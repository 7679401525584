import React from 'react'
import styled from 'styled-components';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';
import LogoOphtamax from '../img/logo_ophtamax_spital.png'

import { Link } from 'react-router-dom';

const Container = styled.div`
width:100%;
display: flex;
justify-content: space-between;
height:45px;

@media (max-width: 426px){
    display: none;
}

/* display: none; */
`;
const InfoLinks = styled.div`
background-color: #b9e9eb;
width: 40%;
display: flex;
justify-content: flex-end;
align-items:center;
position: relative;

&::after{
    content: '';
    position: absolute; 
    bottom: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 45px 45px 0 45px;
    border-color: #b9e9eb transparent transparent transparent;
    left: 0%;
  transform: translateX(-50%) translateY(100%);
}


`;
const InfoLink = styled.div`
font-weight:700;
font-size:1.2em;
margin-right:50px;
color:#3a84ff;
display:flex;
align-items: center;
`;
const Icons = styled.div`
display: flex;
background-color: #ef8a5f;
color: white;
width: 50%;
align-items:center;
gap:10px;
padding-left: 50px;
position: relative;

&::after{
    content: '';
  position: absolute;
  bottom: 100%;
  width: 0;
  height: 0;
    border-style: solid;
    border-width: 45px 45px 0 45px;
    border-color: #fff transparent transparent transparent;
    left: 100%;
  transform: translateX(-50%) translateY(100%);
}
`;

const LogoOphtamaxImg = styled.img`
height: 50px;
margin-right: 1.5rem;
@media (max-width: 426px){
margin-inline: auto;
}

`;

const Topbar = () => {
  return (
    <Container>
            <Icons>
                <Link to="https://wa.me/40745999268" style={{textDecoration:"none", color:"inherit", display:"flex", alignItems:"center"}}>
                    <WhatsAppIcon/>
                </Link>
                <Link to="https://www.facebook.com/ophtamax/" style={{textDecoration:"none", color:"inherit", display:"flex", alignItems:"center"}}>
                    <FacebookIcon/>
                </Link>
                <Link to="https://www.google.com/maps/dir//Ophtamax+Clinica+de+Oftalmopediatrie,+Strada+George+Bari%C8%9Biu,+Ploie%C8%99ti/@44.9426798,26.0346683,18.04z/data=!4m9!4m8!1m0!1m5!1m1!1s0x40b2498b72bb934b:0x4f612ecf5000dc51!2m2!1d26.0351751!2d44.9433065!3e0?entry=ttu" style={{textDecoration:"none", color:"inherit", display:"flex", alignItems:"center"}}>
                    <LocationOnIcon/>
                </Link>
                <Link to="tel:0745999268" style={{textDecoration:"none", color:"inherit", display:"flex", alignItems:"center"}}>
                    <CallIcon/>
                </Link>
                <Link to="tel:0745999268" style={{ textDecoration:"none", color:"inherit", fontSize: "1.2em", fontWeight:"700"}}>
                  0745999268
                </Link>
            </Icons>
            <InfoLinks>

            <Link to="/contact" style={{textDecoration:"none", color:"inherit"}}>
                 <InfoLink> 24.01 | ÎNCHIS</InfoLink>
            </Link>
            <Link to="https://www.ophtamax.ro" style={{ textDecoration:"none", color:"inherit"}}>
            <LogoOphtamaxImg  src={LogoOphtamax}/>
            </Link>
            <Link to="/contact" style={{textDecoration:"none", color:"inherit"}}>
                <InfoLink>CONTACT</InfoLink>
            </Link>
            </InfoLinks>
        </Container>
  )
}

export default Topbar