import React from 'react'
import { Link } from 'react-router-dom';
import HeroPhoto from '../img/pret_img.png'
import styled from 'styled-components';

const Container = styled.div`
width:100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items:center;
margin-bottom: 3rem;
`;

const Wrapper = styled.div`
display: flex;
max-width:1300px;
/* background-color:red; */
/* margin: 0 auto; */
width:100%;
@media (max-width: 426px){
  width:95%;
  flex-direction: column-reverse ;
}
`;

// HeroIMG

const HeroSection = styled.div`
display: flex;
/* margin-top: 25px; */

@media (max-width: 426px){
  flex-direction: column ;
}
`;
const HeroImgWrapper = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
width: fit-content;
@media (max-width: 426px){
    justify-content: flex-start;
    
}
`;
const HeroImg = styled.img`
max-width: 100%;
width:50svw;
@media (max-width: 426px){
  width:100%;
}
`;

const HeroWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-around;
margin: 0px 30px 5%;
max-width: 40%;
/* margin-bottom:5%; */
/* max-width:400px; */

@media (max-width: 426px){
  align-items: center;
  max-width: 100%;
}

`;

const HeroText = styled.div`
color: #3a84ff;
text-indent: 5%;
font-weight: 300;
font-size:1.3rem;
font-style: italic;
@media (max-width: 426px){ 
  flex-direction: column;
}
`;



const HeroTitle = styled.h1`
font-weight: 700;
color: #26a695;
font-style: italic;
font-size: clamp(1.625rem, 1.5vw + 1rem, 2.25rem);


`;

const HeroBtn = styled.button`
background-color: #ef8a5f;
color:white;
height: 45px;
border: none;
display: flex;
align-items: center;
border-radius: 20px;
gap: 7px;
padding: 5px 15px 5px 10px;
font-weight: 600;
font-size:1.2rem;
margin-block:12px;
cursor: pointer;

`;



const TableContainer = styled.table`
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
border-radius:15px;
border: 0;
/* margin:10px; */
width:100%;
`;
const TableHead = styled.thead`
padding:50px;
`;
const TableBody = styled.tbody`

`;
const TableRow = styled.tr`

background-color: #f2f3f3;
&:nth-of-type(odd){
  
  background-color: #d8f6ff;
}

`;
const TableHeadCell = styled.th`
background-color: #ef8a5f;
text-align:left;
font-size:1.6rem;
color: #fff;
padding:10px;
`;
const TableCell = styled.td`
font-size: 20px;
padding:10px;
`;



const Preturi = () => {

  const priceInfos = [
    {
      serviciu: 'Consultaţie oftalmopediatrie – dr. Oana Andrei',
      pret: 350,
    },
    {
      serviciu: 'Consultaţie oftalmopediatrie urgenţă – dr. Oana Andrei',
      pret: 400,
    },
    {
      serviciu: 'Consultaţie oftalmopediatrie in regim prioritar – dr. Oana Andrei',
      pret: 500,
    },
    {
      serviciu: 'Consultaţie oftalmopediatrie – dr. Andreea Dăncescu',
      pret: 300,
    },
    {
      serviciu: 'Consultaţie oftalmopediatrie – dr. Irina Velcea',
      pret: 250,
    },
    {
      serviciu: 'Consultaţie oftalmopediatrie – dr. Alina Moroianu',
      pret: 250,
    },
    {
      serviciu: 'Consultaţie oftalmopediatrie – dr. Georgiana Milea',
      pret: 250,
    },
    {
      serviciu: 'Consultaţie oftalmopediatrie – dr. Mihaela Simion',
      pret: 250,
    },
    {
      serviciu: 'Consultaţie oftalmopediatrie – dr. Alexandru Rută',
      pret: 250,
    },
    {
      serviciu: 'Consultaţie oftalmologică(adulți) – dr. Andreea Dăncescu',
      pret: 250,
    },
    {
      serviciu: 'Consultaţie oftalmologică(adulți) – dr. Mihaela Simion',
      pret: 250,
    },
    {
      serviciu: 'Consultaţie oftalmologică(adulți) – dr. Alexandru Rută',
      pret: 200,
    },    
    {
      serviciu: 'Control oftalmopediatrie - dr. Oana Andrei',
      pret: 150,
    },
    {
      serviciu: 'Control oftalmopediatrie',
      pret: 80,
    },
    {
      serviciu: 'Topografie',
      pret: 100,
    },
    {
      serviciu: 'Măsurătoare ax antero-posterior',
      pret: 100,
    },
    {
      serviciu: 'Pahimetrie',
      pret: 50,
    },
  ]
  return (
    <Container>
      <HeroSection>
            <HeroImgWrapper>

            <HeroImg src={HeroPhoto}/>
            </HeroImgWrapper>
            <HeroWrapper>

            <HeroTitle>Servicii și Tarife</HeroTitle>
            <HeroText>În această secțiune regăsiți informațiile și tarifele serviciilor medicale efectuate în clinica noastră. 
                </HeroText>
              <HeroText>
                Va rugăm nu ezitați să ne 

            <Link to="/contact" style={{textDecoration:"none", color:"inherit"}}>
               <b> contactați </b>  
            </Link>

                pentru mai multe informații și răspunsuri la întrebările pe care le aveți. Personalul nostru din recepție și call-center este pregătit în acest sens și va vor oferi tot suportul de care aveți nevoie.</HeroText>
            <HeroText>Consultaţiile și investigaţiile se achită la finalul consultaţiei, iar <b>plata</b> poate fi efectuată în <b>numerar și/sau card.</b></HeroText>
            
            <Link to="/programare" style={{textDecoration:"none", color:"inherit"}}>
            
            <HeroBtn>Programează-te</HeroBtn>
            </Link>
            </HeroWrapper>
           
        </HeroSection>

      <Wrapper>
        <TableContainer>
          <TableHead>
            <TableRow>
              <TableHeadCell>Servicii</TableHeadCell>
              <TableHeadCell>Preț</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {priceInfos.map(priceInfo => (
            <TableRow>
              <TableCell>{priceInfo.serviciu}</TableCell>
              <TableCell>{priceInfo.pret} lei</TableCell>
            </TableRow>
             ))}
          </TableBody>
        </TableContainer>

      </Wrapper>
    </Container>
  );
}

export default Preturi